<template>
  <div class="UserAdminMenu">
    <AdminMenuOptions :settings="{ scrollIntoView: true }" :modal="modal" />

    <Spacer num="1" :style="sepStyle" />

    <div class="CreatorBlock">
      <UserSmartMenu :modal="modal" />
    </div>

    <Spacer num="1" :style="sepStyle" v-if="$isCreator" />

    <div class="CreatorBlock" v-if="$isCreator">
      <CreatorNewPostMenu :modal="modal" />
    </div>

    <Spacer num="1" :style="sepStyle" />

    <div class="BecomeBlock" dpadding v-if="!$isCreator">
      <UserBecomeCreatorBtn />
    </div>

    <Spacer num="1" :style="sepStyle" />

    <div class="ButtonsBlock">
      <div class="ButtonsBlockWrap">
        <LargeButton :label="$locale['watch']" icon="ig_video_solid" @click="goTo('/watch')" />
        <LargeButton :label="$locale['messages']" icon="comment_dots" @click="goTo('?messages=wall')" />
        <LargeButton :label="$locale['notifications']" icon="bell" @click="goTo($links['my-notifications'])" />
        <LargeButton :label="$locale['my_favorites_creators']" icon="face-grin-stars" @click="goTo('/?feed=favorites-creators')" />
        <LargeButton :label="$locale['explore_creators']" icon="wpexplorer" @click="goTo('/?feed=explore-creators')" />
        <LargeButton :label="$locale['gallery_label']" icon="images" @click="goTo('/?feed=gallery')" />
        <LargeButton
          :label="$locale['help_label']"
          icon="help"
          @click="modal.close(() => Go.sleep(300).then(openSupportView))"
          :desc="$locale['contact_our_support']"
        />
      </div>
    </div>
    <Spacer num="1" :style="sepStyle" />
    <div v-gap="['0', '0', '0', '0']">
      <LargeButton
        icon="power-off"
        :label="$locale['close_session']"
        :desc="$locale['close_session_desc']"
        @click="
          modal.close(() =>
            view({
              title: $locale['close_session'],
              component: () => import('@/components/user/CloseSessionConfirm.vue'),
              closeOutside: true,
              class: 'menu bottom',
              animation: 'bottomIn',
            }),
          )
        "
      />
    </div>
    <Spacer num="1" :style="sepStyle" />
    <Spacer v-if="$isMobile" num="1" :style="sepStyle" />
    <Spacer :num="$isMobile ? 2 : 1" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  data: function() {
    return {
      sepStyle: {
        "background-color": "var(--body-background)",
        "border-top": "solid 1px var(--dborder)",
        "border-bottom": "solid 1px var(--dborder)",
      },
    };
  },
  components: {
    AdminMenuOptions: () => import("../admin/AdminMenuOptions.vue"),
    UserBecomeCreatorBtn: () => import("../user/UserBecomeCreatorBtn.vue"),
    CreatorNewPostMenu: () => import("../creator/CreatorNewPostMenu.vue"),
    UserSmartMenu: () => import("../user/UserSmartMenu.vue"),
  },
  methods: {
    goTo: function(to) {
      this.modal.close(() => {
        this.$router.push(to).catch(() => {});
      });
    },
  },
  watch: {
    $route: function() {
      this.modal.close();
    },
  },
};
</script>

<style lang="scss">
.UserAdminMenu {
  .ButtonsBlock {
    background-color: var(--body-background);
  }
  .ButtonsBlockWrap {
    border-radius: 0;
    background-color: var(--theme-background);
    overflow: hidden;
  }
  .BecomeBlock {
    .become-creator-btn .iconic {
      color: #fff;
    }
  }
}
</style>
